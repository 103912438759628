<template>
  <div>
    <defaultTemplate v-loading.fullscreen.lock="loading">
      <div class="pd-x-5 pd-y-3">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class=""
          v-if="BadgeById"
        >
          <el-breadcrumb-item :to="{ path: `/badges-history` }">
            <span class="text-all font-12">Badge History</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item
            :to="{ path: `/badges-history/${this.SubjectId}` }"
            ><span class="font-12 text-all"
              >{{ BadgeById[0].courseName }}. ({{ BadgeById[0].course }})</span
            ></el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><span class="font-12 text-breadcrumb">
              {{ BadgeDetail[0].courseId }}.
              {{ BadgeDetail[0].skillType }}</span
            ></el-breadcrumb-item
          >
        </el-breadcrumb>

        <div v-if="BadgeDetail">
          <div class="is-flex js-between pd-t-5 border-b">
            <p class="font-14 color-35 pd-t-5 font-weight-500">
              {{ BadgeDetail[0].courseId }}.{{ BadgeDetail[0].skillType }} ({{
                BadgeDetail[0].sessionName
              }})
            </p>
            <p
              class="font-12 pd-t-5 font-weight-400 color-blue-dark"
              v-if="BadgeDetail[0].learningHour > 1"
            >
              {{ BadgeDetail[0].learningHour + " Learning hours" }}
            </p>
            <p class="font-12 pd-t-5 font-weight-400 color-blue-dark" v-else>
              {{ BadgeDetail[0].learningHour + " Learning hour" }}
            </p>
          </div>

          <div class="mg-t-5 text-center">
            <p class="color-35 font-14 font-weight-500">
              {{ BadgeDetail[0].topic }}
            </p>

            <p class="color-35 font-14 font-weight-500">
              {{ BadgeDetail[0].speakerName }}
            </p>
            <p class="color-70 font-12 font-weight-500">
              {{ showDateTH(BadgeDetail[0].sessionDate) }} เวลา
              {{ sessionTime(BadgeDetail[0].sessionStartTime) }} น. -
              {{ sessionTime(BadgeDetail[0].sessionEndTime) }} น.
            </p>
            <p class="color-blue font-10 font-weight-400">
              {{ "Activity : " + BadgeDetail[0].activityDescription }}
            </p>
            <p class="color-blue font-10 font-weight-400">
              {{ "Session Name : " + BadgeDetail[0].sessionName }}
            </p>
          </div>
        </div>
      </div>
    </defaultTemplate>
  </div>
</template>

<script>
import { HTTP } from "@/service/axios";
import defaultTemplate from "@/template/default.vue";
import momentTH from "moment/locale/th";
import moment from "moment";
export default {
  name: "BadgeHistoryDetailById",
  components: {
    defaultTemplate
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    SubjectId() {
      return this.$route.params.id;
    },
    BagdeId() {
      return this.$route.params.idBadge;
    }
  },
  mounted() {
    this.getBadgesHistory();
  },
  data() {
    return {
      BadgeById: null,
      BadgeDetail: null,
      loading: false
    };
  },
  methods: {
    getBadgesHistory() {
      console.log("getBadgesHistory");
      this.loading = true;
      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.get(`badges/history`)

        .then(res => {
          console.log(res.data.obj, "res");
          if (res.data.success) {
            let BadgesHistory = res.data.obj;
            this.BadgeById = BadgesHistory.filter(
              item => item.courseId == this.SubjectId
            );

            console.log(this.BadgeById[0].badges, "this.BadgeById");
            // this.selectBadgeDetail = this.BadgeById[0].badges[0];
            this.BadgeDetail = this.BadgeById[0].badges.filter(
              bdetail => bdetail._id == this.BagdeId
            );
            console.log(this.BadgeDetail, "BadgeDetail");
          }
        })
        .catch(e => {
          console.log(e);
          this.alertCatchError(e.response.status);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
